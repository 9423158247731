import { PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import {
  SuPolicyName,
  getLogoutRequest,
  getMsalConfig,
  signupRequest,
} from "./authConfig";

export async function handleSignupFlow(): Promise<PublicClientApplication> {
  const MSALObj = new PublicClientApplication(getMsalConfig(SuPolicyName));
  await MSALObj.initialize();

  const result = await MSALObj.handleRedirectPromise();
  if (result == null) {
    const params = new URLSearchParams(location.search);
    if (!params.has("id")) throw Error("Ooops, something is wrong. ");
    const currentAccounts = MSALObj.getAllAccounts();
    if (currentAccounts.length > 0) {
      if (
        confirm(
          "You are currently logged in. If you want to continue registration you will be automatically signed out. Do you want to continue?"
        )
      ) {
        const soReq = getLogoutRequest(location.href);
        await MSALObj.logoutRedirect(soReq);
      } else {
        location.replace("/");
      }
    }

    const idQsParam = params.get("id") as string;

    const inviteIsValidResp = await fetch("/api/validateInvite/" + idQsParam);
    if (!inviteIsValidResp.ok) throw Error("Invite is not valid");
    var isValidResult: { valid: boolean } = await inviteIsValidResp.json();
    if (!isValidResult.valid) throw Error("Invite is not valid");

    const req: RedirectRequest = {
      ...signupRequest,
      correlationId: idQsParam,
      state: idQsParam,
      onRedirectNavigate: (a) => {
        console.log(a);
      },
    };
    await MSALObj.loginRedirect(req);
  } else {
    if (result.state) {
      await fetch("/api/acceptInvite/" + result.state, {
        method: "POST",
        body: JSON.stringify(result),
        headers: {
          Authorization: "Bearer " + result.accessToken,
          "Content-Type": "application/json",
        },
      });
    }

    return MSALObj;
  }
  return MSALObj;
}

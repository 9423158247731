import { ApexOptions } from "apexcharts";
import { chartLocales, fDate, fNum, t } from "./helpres";
import { CostsChart } from "./costsChart";

export function getMainChartOptions(
  this: CostsChart,
  series: ApexAxisChartSeries,
  annotations: ApexAnnotations
) {
  const options: ApexOptions = {
    chart: {
      locales: chartLocales,
      //@ts-ignore
      defaultLocale: userContext.user.language.toLowerCase() ?? "nl",
      id: "mainChart",
      toolbar: {
        autoSelected: "pan",
        show: false,
      },
      type: "area",
      height: 300,
      foreColor: "#999",
      stacked: false,
      animations: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        top: -2,
        left: 2,
        blur: 5,
        opacity: 0.06,
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: series,
    markers: {
      size: 4,
      strokeColors: "#fff",
      strokeWidth: 2,
      strokeOpacity: 1,
      fillOpacity: 1,
      hover: {
        size: 6,
      },
    },
    annotations: annotations,
    xaxis: {
      type: "datetime",

      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: true,
      },
      tooltip: {
        enabled: false,
      },
      labels: {
        formatter: this.xDateFormatter,
        format: this.xDateFormat,
        showDuplicates: false,
      },
    },
    yaxis: {},
    grid: {
      // This is not working due to a bug!
      // column: {
      //   colors: ["lightgray", "transparent"],
      // },
    },
    tooltip: {
      shared: false,
      cssClass: "tt",
      // x: {
      //   formatter: (val) => {
      //     return new Date(val).toLocaleDateString();
      //   },
      //   // format: "dd MMM yyyy",
      // },
      // z: {
      //   title: "",
      // },

      custom: ({ seriesIndex, dataPointIndex }) => {
        const xx: [string, number, string] = series[seriesIndex]?.data[
          dataPointIndex
        ] as [string, number, string];
        const titleDate = fDate(new Date(xx[0]), "date");

        if (seriesIndex !== 0 && seriesIndex !== 1) {
          let text = xx[2];

          return `<div class="estimate-tooltip">
          <div class="title">${titleDate}</div>         
          <div class="line">${text}</div>
          </div>`;
        }

        const total = fNum(xx[1], 2);

        const items = xx[2]
          .split(";")
          .map((x) => `<div class="line">${x}</div>`)
          .join("");
        return `<div class="estimate-tooltip">
        <div class="title">${titleDate}</div>
        ${items}
        <div class="line last"><b>${t(
          "project.actual_costs.graph.totalAmountCumulativeForecast"
        )}: ${total}</b></div>
        </div>`;
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    stroke: {
      curve: this.lineType, //"smooth", //"stepline"
      width: 2,
      show: true,
      lineCap: "square",
      fill: {
        opacity: 1,
      },
    },
    fill: {
      //type: "solid",
      //opacity: 0.6,
      type: "gradient",
      gradient: {
        opacityFrom: 0.9,
        opacityTo: 0.7,
      },
    },
  };
  return options;
}

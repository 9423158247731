import { LitElement, css, html } from "lit";
import { customElement } from "lit/decorators.js";
import ApexCharts from "apexcharts";
import "@material/web/chips/filter-chip.js";
import "@material/web/chips/chip-set.js";
import { MdFilterChip } from "@material/web/chips/filter-chip.js";
import {
  CostsViewModel,
  chartLocales,
  fCur,
  fNum,
  getWeek,
  t,
} from "./helpres";
import { getSeries } from "./getSeries";
import { getAnnotations } from "./getAnnotations";
import { getChartMapOptions } from "./getChartMapOptions";
import { getMainChartOptions } from "./getMainChartOptions";

@customElement("sim-costs-chart")
export class CostsChart extends LitElement {
  static override styles = css`
    :host {
      display: block;
      position: relative;
      box-sizing: border-box;
      --md-sys-color-surface: #fff;
      --md-sys-color-primary: var(--color-main);
      margin-top: -28px;
    }
    .estimate-tooltip {
      padding: 10px;
    }

    .estimate-tooltip b {
      color: var(--color-main);
    }

    .estimate-tooltip b.start {
      color: green;
    }
    .estimate-tooltip b.end {
      color: #7b4dc6;
    }

    .estimate-tooltip b.prog {
      color: #82c07a;
    }

    .estimate-tooltip > .title {
      color: #666;
      border-bottom: 2px solid #666;
      padding-bottom: 3px;
    }
    .estimate-tooltip > .line {
      margin-top: 5px;
    }
    .estimate-tooltip > .line.last {
      border-top: 1px solid var(--color-main);
      font-size: small;
      padding-top: 3px;
    }
    .tt {
      top: 80px !important;
    }

    #mapWrapper {
      margin-top: -43px;
      //width: calc(100% - 68px);
    }

    #mapWrapper,
    #chartWrapper {
      position: relative;
      display: block;
      box-sizing: border-box;
      margin-right: 10px;
    }
    #mapWrapper .apexcharts-yaxis {
      opacity: 0;
    }
    md-filter-chip {
      --md-filter-chip-container-height: 18px;
      --md-filter-chip-container-shape: 20px;
      --md-filter-chip-label-text-size: 12px;
      --md-filter-chip-icon-size: 12px;
      --md-filter-chip-label-text-color: #666;
      --md-filter-chip-elevated-selected-container-color: #99cca3;
      --md-filter-chip-elevated-container-color: #f1f4f1;
      --md-filter-chip-elevated-container-shadow-color: transparent;
      --md-filter-chip-label-text-font: Rubik-Regular, sans-serif;
    }

    md-chip-set {
      width: 400px;
      justify-content: end;
      position: absolute;
      right: 400px;
      margin-top: 4px;
    }

    md-filter-chip[data-type="line"] {
      margin-right: 20px;
    }
  `;

  chartDiv = document.createElement("div");
  chartMapDiv = document.createElement("div");
  mainChart: ApexCharts | undefined;
  chartMap: ApexCharts | undefined;
  data: Promise<CostsViewModel> | undefined;
  viewPortWidth: number = 7;

  private loading = false;
  private reloadListener = async () => {
    if (
      this.loading ||
      !this.isConnected ||
      !location.pathname.toLocaleLowerCase().includes("/budget")
    )
      return;

    this.loading = true;
    await this.loadChartData();
    await this.renderChart();
    this.loading = false;
  };

  override async connectedCallback() {
    super.connectedCallback();

    //@ts-ignore
    globalThis.ApexCharts = ApexCharts;

    window.addEventListener("budget-reload", this.reloadListener);
  }

  override disconnectedCallback(): void {
    super.disconnectedCallback();
    window.removeEventListener("budget-reload", this.reloadListener);
  }

  async loadChartData() {
    const resp = await fetch(
      location.pathname
        .replace("budget", "Statistics/costs")
        .replace("project", "api/project")
    );
    this.data = resp.json() as Promise<CostsViewModel>;
    return this.data;
  }

  normalizeDate(d: string | Date | number) {
    const sd = new Date(d);
    return new Date(sd.getFullYear(), sd.getMonth(), sd.getDate(), 1, 0, 0, 0);
  }

  private getChartStartAndEnd(d: CostsViewModel) {
    let startDate = this.normalizeDate(d.project.startDate);
    let endDate = this.normalizeDate(d.project.endDate);

    const minScopeStart = new Date(
      Math.min(
        ...d.scopes.map((x) => this.normalizeDate(x.startDate).getTime())
      )
    );

    if (
      startDate.getFullYear() === 1901 ||
      (minScopeStart.getFullYear() > 1970 && minScopeStart < startDate)
    ) {
      startDate = minScopeStart;
    }
    if (d.costs[0]?.date) {
      const cd = new Date(d.costs[0].date).getTime();
      if (
        new Date(cd).getFullYear() > 1901 &&
        (startDate.getFullYear() < 2000 || cd < startDate.getTime())
      )
        startDate = this.normalizeDate(cd);
    }

    const maxScopeEnd = new Date(
      Math.max(...d.scopes.map((x) => this.normalizeDate(x.endDate).getTime()))
    );
    if (endDate.getFullYear() === 1901 || maxScopeEnd) {
      endDate = maxScopeEnd;
    }
    const lastCost = d.costs.reverse()[0];
    if (lastCost) {
      const cd = new Date(lastCost.date).getTime();
      if (cd > endDate.getTime()) endDate = this.normalizeDate(cd);
    }

    return {
      minDate: startDate.getTime() + -2 * 86400000,
      maxDate: endDate.getTime() + 2 * 86400000,
    };
  }

  private getSeries(
    d: CostsViewModel,
    minMaxDates: {
      minDate: number;
      maxDate: number;
    }
  ): ApexAxisChartSeries {
    return getSeries.bind(this)(d, minMaxDates);
  }

  private getAnnotations(d: CostsViewModel): ApexAnnotations {
    return getAnnotations.bind(this)(d);
  }

  private minMaxDates: { minDate: number; maxDate: number } = {
    minDate: 0,
    maxDate: 0,
  };
  async renderChart() {
    const d = await this.data;
    if (d == undefined) return;

    //this.setProjectStartAndEnd(d);
    this.minMaxDates = this.getChartStartAndEnd(d);
    const series = this.getSeries(d, this.minMaxDates);
    const annotations = this.getAnnotations(d);

    const YOptions = this.getYAxisOptions(series, d.project.budget);

    const opts = await this.getMainChartOptions(series, annotations);
    if (!opts) return;

    opts.yaxis = YOptions;

    if (!this.mainChart) {
      this.mainChart = new ApexCharts(this.chartDiv, opts);
      await this.mainChart.render();
    } else await this.mainChart.updateOptions(opts, true, true, true);

    const opts1 = await this.getChartMapOptions(series, this.minMaxDates);
    if (!opts1) return;
    opts1.yaxis = YOptions;
    opts1.yaxis.title!.text = "Amount";
    if (!this.chartMap) {
      this.chartMap = new ApexCharts(this.chartMapDiv, opts1);
      await this.chartMap.render();
    } else await this.chartMap.updateOptions(opts1, true, true, true);
  }

  //protected override updated() {}

  lineType: "monotoneCubic" | "smooth" | "stepline" | "straight" = "straight";

  getMinMaxDate(project: {
    budget: number;
    startDate: string | Date;
    endDate: string | Date;
  }) {
    return this.minMaxDates;
  }

  private getYAxisOptions(series: ApexAxisChartSeries, projBudget: number) {
    const maxVals = [projBudget];
    series.forEach((x) =>
      maxVals.push(Math.max(...x.data.map((d) => (d as number[])[1] ?? 0)))
    );
    const maxYValue = Math.max(...maxVals);
    return {
      axisTicks: {
        show: true,
      },
      labels: {
        align: "left",
        formatter: (val, opts) => {
          return fCur() + fNum(val, 0);
        },
      },
      opposite: true,
      tooltip: {
        enabled: true,
      },
      title: {
        text: t("costChart.amountCumulative"),
      },
      max: maxYValue + 0.1 * maxYValue,
    } as ApexYAxis;
  }
  private getChartMapOptions(
    series: ApexAxisChartSeries,
    minMaxdate: {
      minDate: number;
      maxDate: number;
    }
  ) {
    return getChartMapOptions.bind(this)(series, minMaxdate);
  }
  private getMainChartOptions(
    series: ApexAxisChartSeries,
    annotations: ApexAnnotations
  ) {
    return getMainChartOptions.bind(this)(series, annotations);
  }

  constructor() {
    super();
  }
  override render() {
    return html`
      <md-chip-set>
        <md-filter-chip
          elevated
          data-type="line"
          label=${t("costChart.stepLine")}
          ?selected=${this.lineType !== "straight"}
          @click=${this.setLineType}
        ></md-filter-chip>
        <md-filter-chip
          elevated
          data-type="view"
          data-vp="w"
          label=${t("costChart.week")}
          @click=${() => this.setVP("w")}
        ></md-filter-chip>
        <md-filter-chip
          elevated
          data-type="view"
          data-vp="m"
          label=${t("costChart.month")}
          @click=${() => this.setVP("m")}
        ></md-filter-chip>
        <md-filter-chip
          elevated
          data-type="view"
          data-vp="y"
          label=${t("costChart.year")}
          @click=${() => this.setVP("y")}
        ></md-filter-chip>
        <md-filter-chip
          elevated
          data-type="view"
          data-vp="max"
          label=${t("costChart.max")}
          selected
          @click=${() => this.setVP("max")}
        ></md-filter-chip>
      </md-chip-set>

      <div id="chartWrapper">${this.chartDiv}</div>
      <div id="mapWrapper">${this.chartMapDiv}</div>
    `;
  }

  setVP(vp: "max" | "y" | "m" | "w") {
    const chips = this.renderRoot.querySelectorAll("[data-type=view]");

    for (const c of chips) {
      const chip = c as MdFilterChip;
      chip.selected = chip.dataset.vp === vp;
    }

    this.setViewport(vp);
  }

  viewportStart: number | undefined;
  viewportEnd: number | undefined;
  async setViewport(v: "w" | "m" | "y" | "max" = "max") {
    const today = this.normalizeDate(new Date());
    const d = await this.data;
    if (d) {
      //this.setProjectStartAndEnd(d);
      const minMaxDates = this.getMinMaxDate(d.project);

      const projDays = (minMaxDates.maxDate - minMaxDates.minDate) / 86400000;

      if (v === "max") {
        this.setXAxis("a");
        this.viewportStart = minMaxDates.minDate;
        this.viewportEnd = minMaxDates.maxDate;
      } else if (v === "w") {
        this.setXAxis("d");
        this.viewportStart =
          projDays <= 13
            ? minMaxDates.minDate
            : new Date(today).setDate(today.getDate() - 3);
        const vpEnd = new Date(today).setDate(today.getDate() + 10);
        this.viewportEnd = Math.min(vpEnd, minMaxDates.maxDate);
      } else if (v === "m") {
        this.setXAxis("w");
        this.viewportStart =
          projDays <= 37
            ? minMaxDates.minDate
            : new Date(today).setDate(today.getDate() - 3);
        const vpEnd = new Date(today).setDate(today.getDate() + 34);
        this.viewportEnd = Math.min(vpEnd, minMaxDates.maxDate);
      } else if (v === "y") {
        this.setXAxis("m");
        const w = 365;
        this.viewportStart =
          projDays <= w
            ? minMaxDates.minDate
            : new Date(today).setDate(today.getDate() - 3);
        const vpEnd = new Date(today).setDate(today.getDate() + w + 3);
        this.viewportEnd = Math.min(vpEnd, minMaxDates.maxDate);
      }
    }

    await this.renderChart();
  }

  xDateFormatter:
    | ((
        value: string,
        timestamp?: number | undefined,
        opts?: any
      ) => string | string[])
    | undefined;
  xDateFormat: string | undefined;
  setXAxis(y: "a" | "y" | "m" | "w" | "d") {
    //const today = this.normalizeDate(new Date());

    switch (y) {
      case "a":
      default:
        this.xDateFormat = undefined;
        this.xDateFormatter = undefined;
        break;
      case "y":
        this.xDateFormat = "yyyy";
        this.xDateFormatter = undefined;
        //this.viewPortWidth = 365;
        break;
      case "m":
        this.xDateFormat = "MMMM yy";
        this.xDateFormatter = undefined;
        //today.setMonth(today.getMonth(), 0);
        //this.viewPortWidth = today.getDate();
        break;
      case "w":
        this.xDateFormat = undefined;
        this.xDateFormatter = (value: string, timestamp?: number) => {
          if (!timestamp) return "";
          const d = new Date(timestamp);
          const w = getWeek(d).toString();

          const ss = new Date(timestamp);
          const start = new Date(
            ss.setDate(ss.getDate() - ss.getDay() + (ss.getDay() == 0 ? -6 : 1))
          );
          const ee = new Date(timestamp);
          const end = new Date(ee.setDate(ee.getDate() - ee.getDay() + 7));

          const locale =
            chartLocales.find(
              //@ts-ignore
              (l) => l.name === userContext.user.language.toLowerCase()
            ) ?? chartLocales[0];
          const ssM = locale?.options.shortMonths[ss.getMonth()];
          const eeM = locale?.options.shortMonths[ee.getMonth()];

          return `${t(
            "costChart.week"
          )} ${w}: ${ss.getDate()} ${ssM} - ${ee.getDate()} ${eeM}`;
        };

        //this.viewPortWidth = 7;

        break;
      case "d":
        this.xDateFormat = "ddd, dd-MM-yy";
        this.xDateFormatter = undefined;
        //this.viewPortWidth = 2;
        break;
    }
    //this.setViewport();
  }
  setLineType(e: Event) {
    this.lineType = this.lineType === "straight" ? "stepline" : "straight";
    this.renderChart();
  }
}

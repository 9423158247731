import { ApexOptions } from "apexcharts";
import { CostsChart } from "./costsChart";
import { chartLocales } from "./helpres";

export function getChartMapOptions(
  this: CostsChart,
  series: ApexAxisChartSeries,
  minMaxdate: {
    minDate: number;
    maxDate: number;
  }
) {
  this.viewportStart = this.viewportStart ?? minMaxdate.minDate;
  this.viewportEnd = this.viewportEnd ?? minMaxdate.maxDate;

  const options: ApexOptions = {
    chart: {
      locales: chartLocales,
      //@ts-ignore
      defaultLocale: userContext.user.language.toLowerCase() ?? "nl",
      animations: {
        enabled: false,
      },
      events: {
        brushScrolled: (chartContext, data) => {
          this.viewportStart = data.xaxis.min;
          this.viewportEnd = data.xaxis.max;
        },
      },
      id: "chartMap",
      brush: {
        autoScaleYaxis: false,
        target: "mainChart",
        enabled: true,
      },
      selection: {
        enabled: true,
        fill: {
          color: "#000",
          opacity: 0.2,
        },
        xaxis: {
          min: this.viewportStart,
          max: this.viewportEnd,
        },
      },
      height: 100,
      toolbar: {
        show: false,
      },
      type: "area",

      foreColor: "#999",
      stacked: false,
    },
    markers: {
      size: 0,
    },
    dataLabels: {
      enabled: false,
    },
    series: series,
    grid: {
      show: false,
    },
    annotations: {
      xaxis: [
        {
          x: this.normalizeDate(new Date()).getTime(),
          borderColor: "#999",
        },
      ],
    },
    xaxis: {
      type: "datetime",
      // min: minMaxdate.minDate,
      // max: minMaxdate.maxDate,
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: true,
      },
      floating: false,
      labels: {
        formatter: this.xDateFormatter,
        format: this.xDateFormat,
        showDuplicates: false,
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      curve: this.lineType, //"smooth", //"stepline"
      width: 1,
      show: true,
      lineCap: "round",
      fill: {
        opacity: 1,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.9,
        opacityTo: 0.7,
      },
    },
  };
  return options;
}

import {
  Configuration,
  LogLevel,
  SilentRequest,
  RedirectRequest,
  EndSessionRequest,
} from "@azure/msal-browser";

const b2cScopes = ["https://stagesimjoai.onmicrosoft.com/simjo-api/access-api"];
const authorityBaseUrl =
  "https://stagesimjoai.b2clogin.com/stagesimjoai.onmicrosoft.com/";

export const tenantId = "d4541384-899b-49ec-b489-85943ed03616";
export const knownAuthority = "stagesimjoai.b2clogin.com";
const clientId = "c57e428c-a5a0-4cf7-bd6b-662418271ff7";
export const SuPolicyName = "B2C_1_SU";
export const SiPolicyName = "B2C_1_SI";

function getOpenIdMetadata(policy: typeof SiPolicyName | typeof SuPolicyName) {
  // const p = {
  //   [SiPolicyName]:
  //     '{"issuer":"https://stagesimjoai.b2clogin.com/d4541384-899b-49ec-b489-85943ed03616/v2.0/","authorization_endpoint":"https://stagesimjoai.b2clogin.com/stagesimjoai.onmicrosoft.com/b2c_1_si/oauth2/v2.0/authorize","token_endpoint":"https://stagesimjoai.b2clogin.com/stagesimjoai.onmicrosoft.com/b2c_1_si/oauth2/v2.0/token",  "end_session_endpoint":"https://stagesimjoai.b2clogin.com/stagesimjoai.onmicrosoft.com/b2c_1_si/oauth2/v2.0/logout","jwks_uri":"https://stagesimjoai.b2clogin.com/stagesimjoai.onmicrosoft.com/b2c_1_si/discovery/v2.0/keys",  "response_modes_supported": ["query","fragment","form_post"],"response_types_supported": ["code","code id_token","code token","code id_token token","id_token","id_token token","token","token id_token"],"scopes_supported":["openid"],"subject_types_supported":["pairwise"],"id_token_signing_alg_values_supported": ["RS256"],"token_endpoint_auth_methods_supported": ["client_secret_post","client_secret_basic"],"claims_supported":["name","emails","oid","sub","idp","tfp","iss","iat","exp","aud","acr","nonce","auth_time"]}',
  //   [SuPolicyName]:
  return `{"issuer":"https://stagesimjoai.b2clogin.com/d4541384-899b-49ec-b489-85943ed03616/v2.0/","authorization_endpoint":"https://stagesimjoai.b2clogin.com/stagesimjoai.onmicrosoft.com/oauth2/v2.0/authorize?p=${policy}","token_endpoint":"https://stagesimjoai.b2clogin.com/stagesimjoai.onmicrosoft.com/oauth2/v2.0/token?p=${policy}","end_session_endpoint":"https://stagesimjoai.b2clogin.com/stagesimjoai.onmicrosoft.com/oauth2/v2.0/logout?p=${policy}","jwks_uri":"https://stagesimjoai.b2clogin.com/stagesimjoai.onmicrosoft.com/discovery/v2.0/keys?p=${policy}","response_modes_supported":["query","fragment","form_post"],"response_types_supported":["code","codeid_token","codetoken","codeid_tokentoken","id_token","id_tokentoken","token","tokenid_token"],"scopes_supported":["openid"],"subject_types_supported":["pairwise"],"id_token_signing_alg_values_supported":["RS256"],"token_endpoint_auth_methods_supported":["client_secret_post","client_secret_basic"],"claims_supported":["newUser","oid","sub","idp","family_name","given_name","emails","tfp","iss","iat","exp","aud","acr","nonce","auth_time"]}`;
  //};
  //return p[policy];
}

export function getMsalConfig(
  policy: typeof SiPolicyName | typeof SuPolicyName
): Configuration {
  return {
    auth: {
      authorityMetadata: getOpenIdMetadata(policy),
      navigateToLoginRequestUrl: false,
      clientId: clientId,
      authority: authorityBaseUrl,
      knownAuthorities: [knownAuthority],
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              // console.info(message);
              return;
            case LogLevel.Verbose:
              // console.debug(message);
              return;
            case LogLevel.Warning:
              // console.warn(message);
              return;
          }
        },
      },
    },
  };
}

export const signUpPath = "signup";
export const signOutPath = "logout";
export const signUpUrl = window.location.origin + "/" + signUpPath;
export const signOutUrl = window.location.origin + "/" + signOutPath;

export const loginRequest: RedirectRequest = {
  scopes: ["openid", ...b2cScopes],
  authority: authorityBaseUrl + SiPolicyName,
  redirectUri: window.location.origin,
};

export const signupRequest: RedirectRequest = {
  scopes: ["openid", ...b2cScopes],
  authority: authorityBaseUrl + SuPolicyName,
  redirectUri: signUpUrl,
  redirectStartPage: window.location.origin,
};

export const tokenRequest: SilentRequest = {
  scopes: b2cScopes,
  forceRefresh: false,
  redirectUri: window.location.origin,
  authority: authorityBaseUrl + SiPolicyName,
};

export function getLogoutRequest(
  //account: AccountInfo | null,
  redirectUrl = signOutUrl
): EndSessionRequest {
  return {
    authority: authorityBaseUrl,
    postLogoutRedirectUri: redirectUrl,
    //account,
  };
}

import {
  AccountInfo,
  InteractionRequiredAuthError,
  PublicClientApplication,
  SilentRequest,
} from "@azure/msal-browser";
import {
  loginRequest,
  tokenRequest,
  SiPolicyName,
  getLogoutRequest,
  knownAuthority,
  tenantId,
  signOutPath,
  signUpPath,
} from "./authConfig";
import { handleSignupFlow } from "./signUpFlow";
import { handleSignInFlow } from "./signInFlow";
import { initUserContext } from "./userContext";
import {
  ApplicationInsights,
  ITelemetryItem,
  Tags,
} from "@microsoft/applicationinsights-web";
import {
  ClickAnalyticsPlugin,
  IClickAnalyticsConfiguration,
} from "@microsoft/applicationinsights-clickanalytics-js";
import "./costsChart/costsChart";

let appInsights: ApplicationInsights | undefined = undefined;
if (!location.origin.includes("localhost")) {
  const clickPluginInstance = new ClickAnalyticsPlugin();
  const clickPluginConfig: IClickAnalyticsConfiguration = {
    autoCapture: true,
    urlCollectHash: true,
    urlCollectQuery: true,
  };
  appInsights = new ApplicationInsights({
    config: {
      connectionString:
        // IMPORTANT! use empty guid to avoid client side validation error
        "InstrumentationKey=00000000-0000-0000-0000-000000000000;IngestionEndpoint=/ai/in;LiveEndpoint=/ai/le",
      enableAutoRouteTracking: true,
      autoTrackPageVisitTime: true,
      extensions: [clickPluginInstance],
      extensionConfig: {
        [clickPluginInstance.identifier]: clickPluginConfig,
      },
    },
  });
  var telemetryInitializer = (envelope: ITelemetryItem) => {
    if (!envelope.tags) envelope.tags = {} as Tags & Tags[];

    envelope.tags["ai.cloud.role"] = "simjo-browser-app";
    //envelope.tags["ai.cloud.roleInstance"] = "your role instance";
  };
  appInsights.addTelemetryInitializer(telemetryInitializer);
  appInsights.loadAppInsights();
  appInsights.getCookieMgr().setEnabled(false);
  appInsights.trackPageView();
}

const trimChar = (str: string, chars: string) =>
  str.split(chars).filter(Boolean).join(chars);

const url = trimChar(location.pathname, "/");
let accountId = "";
let userId = "";
let username = "";
let accessToken: string | null = null;

let resolveAuthReadyPromise: () => void = () => {
  return;
};

export const authReady = new Promise<void>(
  (res) => (resolveAuthReadyPromise = res)
);

let resolveUserContextReadyPromise: () => void = () => {
  return;
};
export const userContextReady = new Promise<void>(
  (res) => (resolveUserContextReadyPromise = res)
);
//@ts-ignore
window.authReady = userContextReady;
//authReady.then(addAmbreroScripts);

// export async function GetCurrentAccount() {
//   await authReady;
//   return { accountId, username };
// }
export async function GetToken() {
  await authReady;
  if (!url.startsWith(signOutPath)) {
    await getTokenRedirect(tokenRequest);
  }
  return accessToken;
}

if (url.startsWith(signOutPath)) {
  resolveAuthReadyPromise();
  resolveUserContextReadyPromise();
} else {
  (async () => {
    if (url.startsWith(signUpPath)) {
      currentMsalClient = await handleSignupFlow();
      location.replace("/");
    } else {
      currentMsalClient = await handleSignInFlow();
      // appInsights.setAuthenticatedUserContext(
      //   p.account.username,
      //   p.account.homeAccountId,
      //   false
      // );
      selectAccount();
    }
  })();
}
let currentMsalClient: PublicClientApplication;
async function setAccount(account: AccountInfo) {
  if (!account.idTokenClaims)
    await getTokenRedirect(loginRequest, account.homeAccountId);
  accountId = account.homeAccountId;
  userId = account.localAccountId;
  username = account.username || account.name || "Unknown";

  resolveAuthReadyPromise();
  appInsights?.setAuthenticatedUserContext(username, userId, false);
  await initUserContext(GetToken, signOut);
  resolveUserContextReadyPromise();
}

function isSimjoAccount(account: AccountInfo): boolean {
  const accId = account.homeAccountId.toUpperCase();
  return (
    accId.includes(SiPolicyName.toUpperCase()) &&
    accId.includes(tenantId.toUpperCase()) &&
    account.environment.toUpperCase() === knownAuthority.toUpperCase()
  );
}

async function selectAccount() {
  const currentAccounts = currentMsalClient.getAllAccounts();
  const accounts = currentAccounts.filter(isSimjoAccount);

  if (accounts.length === 0) {
    signIn();
    return;
  }

  if (accounts.length >= 1) {
    let firstAccount = accounts[0] as AccountInfo;

    if (accounts.length === 1) {
      setAccount(firstAccount);
      return;
    }

    if (
      accounts.every(
        (account) => account.localAccountId === firstAccount.localAccountId
      )
    ) {
      // All accounts belong to the same user
      setAccount(firstAccount);
    } else {
      // Multiple users detected. Logout all to be safe.
      console.log("Multiple users detected. Logout all to be safe.");
      signOut();
    }
  }
}

export function signIn() {
  currentMsalClient.loginRedirect(loginRequest);
}

export function signOut() {
  currentMsalClient.logoutRedirect(
    getLogoutRequest()
    //MSALObj.getAccount({ homeAccountId: accountId })
  );
}

async function getTokenRedirect(request: SilentRequest, accId?: string) {
  const acc = currentMsalClient.getAccount({
    homeAccountId: accId ?? accountId,
  });
  if (acc) request.account = acc;

  return currentMsalClient
    .acquireTokenSilent(request)
    .then((response) => {
      // In case the response from B2C server has an empty accessToken field
      // throw an error to initiate token acquisition
      if (!response.accessToken || response.accessToken === "") {
        throw new InteractionRequiredAuthError();
      } else {
        accessToken = response.accessToken;
      }
    })
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return currentMsalClient.acquireTokenRedirect(request);
      } else {
        //console.log(error);
        signIn();
        return null;
      }
    });
}

//@ts-ignore
window.getToken = GetToken;

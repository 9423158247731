import { CostsChart } from "./costsChart";
import { CostsViewModel, Scope, fNum, msgKey, t } from "./helpres";

function getEstimateSeries(this: CostsChart, d?: CostsViewModel) {
  if (!d) return [];
  const getLabel = (
    s: string,
    st: "Start" | "End" | "Progress",
    amount?: number
  ) => {
    const tKeys: Record<"Start" | "End" | "Progress", msgKey> = {
      Start: "costChart.tooltipLine_StartOf",
      End: "costChart.tooltipLine_EndOf",
      Progress: "costChart.tooltipLine_ProgressOf",
    };
    const cssKeys: Record<"Start" | "End" | "Progress", string> = {
      Start: "start",
      End: "end",
      Progress: "prog",
    };
    if (amount) {
      const parts = s.split(",");
      const a = fNum(amount / parts.length, 2);
      return parts
        .map((x) => `<b class="${cssKeys[st]}">${t(tKeys[st])}:</b> ${x} +${a}`)
        .join(";");
    } else
      return s
        .split(",")
        .map((x) => `<b>${t(tKeys[st])}:</b> ` + x)
        .join(";");
  };

  const scopes: Map<string, Scope[]> = new Map();
  //1 - Group SAME scopes
  d.scopes.forEach((s) => {
    s.startDate = this.normalizeDate(s.startDate);
    s.endDate = this.normalizeDate(s.endDate);

    const key = `${s.startDate.getTime()}-${s.endDate.getTime()}`;
    const ss = scopes.get(key) ?? [];
    ss.push(s);
    scopes.set(key, ss);
  });

  const v = Array.from(scopes.values());
  const mergedScopes: Scope[] = [];
  //2 - merge grouped scopes
  for (const s of v) {
    const mergedScope: Scope = s.reduce(
      (mergedScope, currentScope) => {
        mergedScope.budget += currentScope.budget;
        mergedScope.name += mergedScope.name !== "" ? ", " : "";
        mergedScope.name +=
          currentScope.name + " +" + currentScope.expectedCosts;
        mergedScope.description += mergedScope.description !== "" ? ", " : "";
        mergedScope.description += currentScope.name;
        mergedScope.expectedCosts! += currentScope.expectedCosts || 0;
        return mergedScope;
      },
      {
        budget: 0,
        description: "",
        endDate: s[0]!.endDate,
        name: "",
        startDate: s[0]!.startDate,
        expectedCosts: 0,
      }
    );

    mergedScopes.push(mergedScope);
  }

  let cum = 0;

  const series: [Date, number, string][] = [];
  // let next = 1;

  // let skipNextStart = false;

  //3 - generate data series with intermediate points
  const calcIntermediateCosts = (
    cStartDate: Date | string,
    cEndDate: Date | string,
    cTotal: number,
    iDate: Date | string
  ) => {
    const sDur = new Date(cEndDate).getTime() - new Date(cStartDate).getTime();

    const diff = new Date(iDate).getTime() - new Date(cStartDate).getTime();
    const durPercent = diff / sDur;
    const intermediateCosts = durPercent * cTotal;
    return intermediateCosts;
  };

  const dates: Date[] = [];

  for (const s of mergedScopes) {
    if (
      dates.findIndex((d) => d.getTime() == (s.startDate as Date).getTime()) ===
      -1
    )
      dates.push(s.startDate as Date);
    if (
      dates.findIndex((d) => d.getTime() == (s.endDate as Date).getTime()) ===
      -1
    )
      dates.push(s.endDate as Date);
  }
  dates.sort((a, b) => a.getTime() - b.getTime());

  const active: Scope[] = [];
  const handled: Scope[] = [];

  const scopes1: Scope[] = mergedScopes.map((x) => ({ ...x }));
  for (const d of dates) {
    const sStart = scopes1.filter((s) => s.startDate === d);
    const sEnd = scopes1.filter((s) => s.endDate === d);
    const tooltips: string[] = [];
    for (const s of sStart) {
      active.push(s);
      tooltips.push(getLabel(s.description, "Start"));
    }
    for (const s of sEnd) {
      const intCosts = calcIntermediateCosts(
        s.startDate,
        s.endDate,
        s.expectedCosts || 0,
        d
      );
      cum += intCosts;
      s.expectedCosts! -= intCosts;
      handled.push(s);
      active.splice(active.indexOf(s), 1);
      tooltips.push(getLabel(s.description, "End", intCosts));
    }

    for (const s of active) {
      if (sStart.includes(s) || sEnd.includes(s)) continue;

      const intCosts = calcIntermediateCosts(
        s.startDate,
        s.endDate,
        s.expectedCosts || 0,
        d
      );
      cum += intCosts;
      s.expectedCosts! -= intCosts;
      tooltips.push(getLabel(s.description, "Progress", intCosts));
      s.startDate = d;
    }
    series.push([d, cum, tooltips.join(";")]);
  }

  return series;
}

export function getSeries(
  this: CostsChart,
  d: CostsViewModel,
  minMaxDates: {
    minDate: number;
    maxDate: number;
  }
): ApexAxisChartSeries {
  // const actualStartDate = new Date(d.costs[0]?.date ?? d.project.startDate);
  // const ad1 = this.normalizeDate(actualStartDate);

  // only add starting point in case if no costs have been added before start of the project
  const actualCostsSeries: [Date, number, string][] = [
    [this.normalizeDate(d.project.startDate), 0, ""],
  ];
  //ad1 <= d.project.startDate ? [] : [[d.project.startDate as Date, 0, ""]];

  let sum = 0;

  for (const f of d.costs.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  )) {
    let aTooltip = "";

    sum += f.amountEx;
    aTooltip = `+ ${fNum(f.amountEx, 0)} - ${f.costDescription}`;

    let d1 = new Date(f.date);
    d1 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate(), 1, 0, 0, 0);
    actualCostsSeries.push([d1, sum, aTooltip]);
  }

  const budgetSeries: [number, number, string][] = [];

  let bSum = 0;

  d.scopes.forEach((s) => {
    s.startDate ??= d.project.startDate;
    s.endDate ??= d.project.endDate ?? d.project.startDate;
  });

  if (d.budgets.length > 0) {
    // const zeroBudgetDate =
    //   new Date(budgetScopes[0]!.startDate) < new Date(d.project.startDate)
    //     ? budgetScopes[0]!.startDate
    //     : d.project.startDate;

    // budgetSeries.push([new Date(zeroBudgetDate), 0, ""]);

    for (const f of d.budgets) {
      let bTooltip = "";
      bSum += f.budget;
      bTooltip = `${fNum(bSum, 0)} (+ ${fNum(f.budget, 0)} ${f.name})`;

      let d1 = this.normalizeDate(f.startDate);
      budgetSeries.push([d1.getTime(), bSum, bTooltip]);
    }
  }
  budgetSeries.push([
    this.normalizeDate(d.project.endDate).getTime(),
    bSum,
    bSum.toString(),
  ]);

  // let sumSnap = 0;
  // const snapSeries = [];
  // for (const s of d.snapScopes ?? []) {
  //   sumSnap += s.expectedCosts ?? 0;
  //   snapSeries.push([
  //     this.normalizeDate(s.endDate),
  //     sumSnap,
  //     "+ " + s.expectedCosts + " - " + s.name,
  //   ]);
  // }

  return [
    {
      zIndex: 4,
      color: "#b9bfc7",
      type: "area",
      name: t("costChart.estimate"),
      data: getEstimateSeries.bind(this)(d),
    },
    {
      zIndex: 2,
      color: "#0090FF",
      type: "line",
      name: t("costChart.snapshot"),
      data: getEstimateSeries.bind(this)(d.snapshot),
    },
    {
      zIndex: 3,
      color: "#158e30",
      type: "area",
      name: t("costChart.actualCosts"),
      data: actualCostsSeries as any,
    },
    {
      color: "#FF9800",
      zIndex: 10,
      type: "line",
      name: t("costChart.budget"),
      data: budgetSeries,
    },
    {
      color: "transparent",
      zIndex: -1,
      type: "line",
      name: "",
      data: [
        [minMaxDates.minDate, 0],
        [minMaxDates.maxDate, 0],
      ],
    },
  ];
}

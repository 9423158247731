export async function initUserContext(
  getToken: () => Promise<string | null>,
  logout: () => void
) {
  window.fetch = new Proxy(window.fetch, {
    apply: async function (
      target,
      that,
      args: [input: RequestInfo | URL, init?: RequestInit | undefined]
    ) {
      const url =
        typeof args[0] === "string" ? args[0] : (args[0] as Request).url;
      if (url.includes("/ai/") || url.includes("b2clogin.com"))
        return await target(args[0], args[1]);

      var accToken = await getToken();
      const defaultHeaders = {
        Authorization: "Bearer " + accToken,
        "Content-Type": "application/json",
      };
      if (args[1] === undefined)
        args[1] = {
          headers: defaultHeaders,
        } as RequestInit;
      else {
        args[1] = {
          ...args[1],
          headers: { ...args[1].headers, ...defaultHeaders },
        };
      }

      const res = that
        ? await target.apply(that, args)
        : await target(args[0], args[1]);

      if (res.status === 401) {
        console.log("WOW!!!! 401!! ");
      }

      return res;
    },
  });

  document.addEventListener("init-ambrero-router", (event) => {
    const ev = event as CustomEvent<{
      setOnBeforeApplyRoute: (handler: () => Promise<void>) => void;
    }>;
    ev.detail.setOnBeforeApplyRoute(async () => {
      //@ts-ignore
      window.userContext = (await (await fetch("/api/session")).json()).data;
    });
  });

  document.addEventListener("on-logout", () => {
    logout();
  });

  //@ts-ignore
  window.userContext = (await (await fetch("/api/session")).json()).data;
}

import {
  // AuthenticationResult,
  // EventMessage,
  // EventPayload,
  // EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { SiPolicyName, getMsalConfig } from "./authConfig";
//onSignIn: (p: AuthenticationResult) => void
export async function handleSignInFlow(): Promise<PublicClientApplication> {
  const MSALObj = new PublicClientApplication(getMsalConfig(SiPolicyName));
  // MSALObj.addEventCallback((message: EventMessage) => {
  //   if (message.eventType === EventType.LOGIN_SUCCESS) {
  //     onSignIn(message.payload as AuthenticationResult);
  //   }
  // });

  await MSALObj.initialize();
  await MSALObj.handleRedirectPromise();

  return MSALObj;
}

export type CostsViewModel = {
  project: {
    budget: number;
    startDate: string | Date;
    endDate: string | Date;
  };
  costs: {
    amountEx: number;
    costDescription: string;
    date: string;
    type: "Expense" | "Actual";
  }[];
  scopes: Scope[];
  budgets: Scope[];
  snapshot?: CostsViewModel;
};

export type Scope = {
  name: string;
  description: string;
  startDate: string | Date;
  budget: number;
  endDate: string | Date;
  expectedCosts?: number;
};

export function getWeek(date: Date) {
  const startDate = new Date(date.getFullYear(), 0, 1);
  let days = Math.floor(
    (date.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)
  );

  const n = Math.ceil(days / 7);

  return n + 1;
}

export type msgKey =
  | "costChart.stepLine"
  | "costChart.week"
  | "costChart.month"
  | "costChart.year"
  | "costChart.max"
  | "costChart.estimate"
  | "costChart.snapshot"
  | "costChart.actualCosts"
  | "costChart.budget"
  | "costChart.projectStart"
  | "costChart.projectEnd"
  | "costChart.today"
  | "costChart.totalBudget"
  | "costChart.amountCumulative"
  | "costChart.tooltipLine_StartOf"
  | "costChart.tooltipLine_EndOf"
  | "costChart.tooltipLine_ProgressOf"
  | "project.actual_costs.graph.totalAmountCumulativeForecast";

export function t(key: msgKey): string {
  //@ts-ignore
  return window.Messages(key);
}

export function fNum(n: number, p: number): string {
  //@ts-ignore
  return Simplex.HandlebarHelper.formatNumber(n, p);
}
export function fCur() {
  //@ts-ignore
  return Simplex.HandlebarHelper.formatCurrency();
}
export function fDate(d: Date, f: "date" | "time" | "dateTime") {
  //@ts-ignore
  return Simplex.HandlebarHelper.formatDate(d, f, f);
}

export const chartLocales = [
  {
    name: "nl",
    options: {
      months: [
        "Januari",
        "Februari",
        "Maart",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Augustus",
        "September",
        "Oktober",
        "November",
        "December",
      ],
      shortMonths: [
        "Jan",
        "Feb",
        "Mrt",
        "Apr",
        "Mei",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dec",
      ],
      days: [
        "Zondag",
        "Maandag",
        "Dinsdag",
        "Woensdag",
        "Donderdag",
        "Vrijdag",
        "Zaterdag",
      ],
      shortDays: ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"],
    },
  },

  {
    name: "en",
    options: {
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      shortMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    },
  },
];

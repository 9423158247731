import { CostsChart } from "./costsChart";
import { CostsViewModel, fCur, fDate, fNum, t } from "./helpres";

export function getAnnotations(
  this: CostsChart,
  d: CostsViewModel
): ApexAnnotations {
  let today = this.normalizeDate(new Date());

  return {
    // yaxis: [
    //   {
    //     y: d.project.budget,
    //     borderColor: "#0000009e", //"#000",
    //     borderWidth: 1,
    //     strokeDashArray: 0,
    //     label: {
    //       position: "left",
    //       offsetX: 200,
    //       borderWidth: 0,
    //       text:
    //         t("costChart.totalBudget") +
    //         ": " +
    //         fCur() +
    //         fNum(d.project.budget, 0),
    //       style: {
    //         color: "#fff",
    //         background: "#0000009e",
    //       },
    //     },
    //   },
    // ],
    xaxis: [
      {
        x: today.getTime(),
        borderColor: "#999",
        // yAxisIndex: 0,
        label: {
          //orientation: "horizontal",
          borderWidth: 0,
          offsetY: -17,
          text: t("costChart.today") + " " + fDate(today, "date"),
          style: {
            color: "#fff",
            background: "#999",
          },
        },
      },
      {
        x: this.normalizeDate(new Date(d.project.startDate)).getTime(),
        borderColor: "green",
        borderWidth: 1,
        label: {
          //offsetY: 10,
          offsetX: -5,
          position: "bottom",
          //orientation: "horizontal",
          borderWidth: 0,
          text:
            t("costChart.projectStart") +
            " " +
            fDate(this.normalizeDate(new Date(d.project.startDate)), "date"),
          style: {
            color: "#fff",
            background: "green",
          },
        },
      },
      {
        x: this.normalizeDate(new Date(d.project.endDate)).getTime(),
        borderColor: "#7B4DC6",
        label: {
          //orientation: "horizontal",
          //orientation: "right",
          borderWidth: 0,

          // offsetY: -17,
          offsetX: 22,
          text:
            t("costChart.projectEnd") +
            " " +
            fDate(new Date(d.project.endDate), "date"),
          style: {
            color: "#fff",
            background: "#7B4DC6",
          },
        },
      },
    ],
  };
}
